import { MediaSize, MediaImageType } from './types';

type MediaSizeConfig = {
  [key in MediaImageType]: Record<MediaSize, number>;
};

export const mediaSizes: MediaSizeConfig = {
  THUMBNAIL: {
    tiny: 490,
    small: 400,
    medium: 320,
    big: 250,
    large: 250,
    huge: 250,
    enormous: 250
  },
  PRESENTATION: {
    tiny: 320,
    small: 490,
    medium: 710,
    big: 1024,
    large: 1200,
    huge: 1560,
    enormous: 1920
  },
  // Basically THUMBNAIL, but a little bigger, as we only have at most 3 columns
  CARGUIDE: {
    tiny: 725,
    small: 725,
    medium: 725,
    big: 340,
    large: 340,
    huge: 340,
    enormous: 340
  },
  /* Currently used in the fullscreenGallery */
  HIGH_QUALITY: {
    tiny: 1170,
    small: 1170,
    medium: 1170,
    big: 1920,
    large: 1920,
    huge: 1920,
    enormous: 1920
  }
};

export const MEDIA_SIZES_ARRAY: MediaSize[] = [
  'tiny',
  'small',
  'medium',
  'big',
  'large',
  'huge',
  'enormous'
];
