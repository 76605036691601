import styled from 'styled-components';

export const Figure = styled.figure`
  position: relative;
  margin: 0;
  min-height: 1px;
`;

type MediaSizeProps = {
  paddingTop: number;
};

export const MediaSize = styled.div<MediaSizeProps>`
  content: ' ';
  display: block;
  position: relative;
  height: 0;
  width: 100%;
  padding-top: ${props => props.paddingTop}%;
`;

type LazyImgProps = {
  isLoaded: boolean;
};

export const LazyImg = styled.img<LazyImgProps>`
  // todo: figure why isLoaded doesn't change server-side (works locally)
  /* opacity: ${props => (props.isLoaded ? 1 : 0)}; */
  transition: opacity 0.2s ease-in-out;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  animation: fadeIn 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
