import React, { RefObject } from 'react';
import { useInView } from 'react-intersection-observer';
import { MediaProps } from './types';
import { MediaInner } from './MediaInner';

const Media = ({ elementRef, isInView, rootMargin, ...props }: MediaProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: rootMargin ?? '50%',
    threshold: 0,
    initialInView: isInView,
    root: elementRef
  });

  /* The type defined in useInView is wrong.. */
  const _ref = ref as unknown as RefObject<HTMLElement>;

  return <MediaInner {...props} forwardRef={_ref} isInView={inView} />;
};

export default Media;
export { MediaInner };
export * from './utils';
