/* eslint-disable max-lines */

import React from 'react';
import loadable from '@loadable/component';
import Logout from './App/views/Logout';
import AppLoaderOverlay from './App/shared/components/AppLoaderOverlay';
import { Spinner, theme } from '@kvdbil/components';
import { RouteConfig } from '~/config/routes/types';
import { TranslateFunction } from '~/Locale';
import {
  RedirectFromAuctions,
  RedirectFromFixedPrice
} from './App/shared/components/RedirectToBuyCar';
import translateRoute from './App/shared/localization/translateRoute';

const Loading = () => (
  <AppLoaderOverlay data-testid="loading" lockBody={false}>
    <Spinner color={theme.colors.gray.light4} size="large" speed="fast" />
  </AppLoaderOverlay>
);

const Start = loadable(() => import('./App/views/Start'), {
  fallback: <Loading />
});

const DynamicPage = loadable(() => import('./App/views/DynamicPage'), {
  fallback: <Loading />
});

const ObjectPage = loadable(() => import('./App/views/ObjectPage'), {
  fallback: <Loading />
});

const GalleryPage = loadable(
  () => import('./App/views/ObjectPage/views/GalleryPage/index'),
  { fallback: <Loading /> }
);

const FilterPageAllVehicles = loadable(
  () => import('./App/views/FilterPage/views/AllVehicles'),
  {
    fallback: <Loading />
  }
);

const FilterPageCar = loadable(
  () => import('./App/views/FilterPage/views/Car'),
  {
    fallback: <Loading />
  }
);

const FilterPageRecreationalVehicle = loadable(
  () => import('./App/views/FilterPage/views/RecreationalVehicle'),
  {
    fallback: <Loading />
  }
);

const FilterPageLightTransport = loadable(
  () => import('./App/views/FilterPage/views/LightTransport'),
  {
    fallback: <Loading />
  }
);

const FilterPageHeavyTransport = loadable(
  () => import('./App/views/FilterPage/views/HeavyTransport'),
  {
    fallback: <Loading />
  }
);

const FilterPageMachines = loadable(
  () => import('./App/views/FilterPage/views/Machines'),
  {
    fallback: <Loading />
  }
);

const CategoryPageCar = loadable(
  () => import('./App/views/CarTreeAndCategory/views/CarCategory'),
  {
    fallback: <Loading />
  }
);
const CategoryPageRecreationalVehicle = loadable(
  () =>
    import('./App/views/CarTreeAndCategory/views/RecreationalVehicleCategory'),
  {
    fallback: <Loading />
  }
);
const CategoryPageLightTransport = loadable(
  () => import('./App/views/CarTreeAndCategory/views/LightTransportCategory'),
  {
    fallback: <Loading />
  }
);
const CategoryPageHeavyTransport = loadable(
  () => import('./App/views/CarTreeAndCategory/views/HeavyTransportCategory'),
  {
    fallback: <Loading />
  }
);
const CategoryPageMachines = loadable(
  () => import('./App/views/CarTreeAndCategory/views/MachinesCategory'),
  {
    fallback: <Loading />
  }
);
const FamilyPageCar = loadable(
  () => import('./App/views/CarTreeAndCategory/views/CarFamily'),
  {
    fallback: <Loading />
  }
);
const Login = loadable(
  () => import('./App/shared/components/Auth/SignIn/views/SignInView'),
  {
    fallback: <Loading />
  }
);
const Verification = loadable(
  () => import('./App/views/Member/views/PrivateVerification'),
  { fallback: <Loading /> }
);
const ResetPassword = loadable(
  () => import('./App/views/Member/views/ResetPassword'),
  { fallback: <Loading /> }
);
const ForgotPassword = loadable(
  () => import('./App/views/Member/views/ForgotPassword'),
  { fallback: <Loading /> }
);
const CreatePassword = loadable(
  () => import('./App/views/Member/views/CreatePassword'),
  { fallback: <Loading /> }
);
const EmailVerification = loadable(
  () => import('./App/views/Member/views/EmailVerification'),
  { fallback: <Loading /> }
);
const Impersonate = loadable(() => import('./App/views/Impersonate'), {
  fallback: <Loading />
});
const PrivateRegistration = loadable(
  () => import('./App/views/Member/views/Registration/PrivateRegistration'),
  { fallback: <Loading /> }
);

const CompanyRegistration = loadable(
  () => import('./App/views/Member/views/Registration/CompanyRegistration'),
  { fallback: <Loading /> }
);
const OrganizationVerification = loadable(
  () => import('./App/views/Member/views/OrganizationVerification'),
  { fallback: <Loading /> }
);
const VerifySocialLogin = loadable(
  () => import('./App/views/Member/views/verifySocialLogin'),
  { fallback: <Loading /> }
);
const Account = loadable(() => import('./App/views/Account'), {
  fallback: <Loading />
});
const AccountMySales = loadable(
  () => import('./App/views/Account/views/MySales'),
  { fallback: <Loading /> }
);
const AccountSettings = loadable(
  () => import('./App/views/Account/views/Settings'),
  { fallback: <Loading /> }
);
const AccountMyAuctions = loadable(
  () => import('./App/views/Account/views/MyAuctions'),
  { fallback: <Loading /> }
);
const BookPickupAppointment = loadable(
  () => import('./App/views/Account/views/BookPickupAppointment'),
  { fallback: <Loading /> }
);
const AccountSavedObjects = loadable(
  () => import('./App/views/Account/views/SavedObjects'),
  { fallback: <Loading /> }
);
const AccountSavedSearches = loadable(
  () => import('./App/views/Account/views/SavedSearches'),
  { fallback: <Loading /> }
);
const AccountSettingsDetails = loadable(
  () => import('./App/views/Account/views/Settings/views/Details'),
  { fallback: <Loading /> }
);
const AccountSettingsPassword = loadable(
  () => import('./App/views/Account/views/Settings/views/Password'),
  { fallback: <Loading /> }
);
const AccountMyAuctionsActive = loadable(
  () => import('./App/views/Account/views/MyAuctions/views/Active'),
  { fallback: <Loading /> }
);
const AccountMyAuctionsClosed = loadable(
  () => import('./App/views/Account/views/MyAuctions/views/Closed'),
  { fallback: <Loading /> }
);
const AccountMyAuctionsCompleted = loadable(
  () => import('./App/views/Account/views/MyAuctions/views/Completed'),
  { fallback: <Loading /> }
);
const AccountMySalesCurrentSales = loadable(
  () => import('./App/views/Account/views/MySales/views/CurrentSales'),
  { fallback: <Loading /> }
);
const AccountMySalesCurrentSaleDetails = loadable(
  () =>
    import(
      './App/views/Account/views/MySales/views/CurrentSales/views/Details/MySaleDetails'
    ),
  { fallback: <Loading /> }
);
const AccountMySalesBusinessProposals = loadable(
  () => import('./App/views/Account/views/MySales/views/BusinessProposals'),
  { fallback: <Loading /> }
);
const PartnershipPage = loadable(() => import('./App/views/PartnershipPage'), {
  fallback: <Loading />
});
const Facility = loadable(
  () => import('./App/views/Facilities/views/Facility'),
  {
    fallback: <Loading />
  }
);
const Facilities = loadable(() => import('./App/views/Facilities'), {
  fallback: <Loading />
});
const SellFlowCar = loadable(() => import('./App/views/SellCar'), {
  fallback: <Loading />
});
const SellCarInterestForm = loadable(
  () => import('./App/views/SellCar/views/InterestForm'),
  { fallback: <Loading /> }
);
const SellHeavyEquipment = loadable(
  () => import('./App/views/SellHeavyEquipment'),
  { fallback: <Loading /> }
);
const SellCarPreFilledInterestForm = loadable(
  () => import('./App/views/SellCar/views/PreFilledInterestForm'),
  { fallback: <Loading /> }
);
const ReservationPrice = loadable(
  () => import('./App/views/SellCar/views/ReservationPrice'),
  { fallback: <Loading /> }
);
const SellCarDone = loadable(
  () => import('./App/views/SellCar/views/Flow/views/Done'),
  { fallback: <Loading /> }
);
const SellCarOwnerVerificationAgreementSent = loadable(
  () =>
    import(
      './App/views/SellCar/views/Confirmation/OwnerVerificationAgreementSent'
    ),
  { fallback: <Loading /> }
);
const SellCarOwnerVerificationAgreementSigned = loadable(
  () =>
    import(
      './App/views/SellCar/views/Confirmation/OwnerVerificationAgreementSigned'
    ),
  { fallback: <Loading /> }
);
const SellCarPrivateAgreementSigned = loadable(
  () => import('./App/views/SellCar/views/Confirmation/PrivateAgreementSigned'),
  { fallback: <Loading /> }
);
const SellCarCompanyAgreementSigned = loadable(
  () => import('./App/views/SellCar/views/Confirmation/CompanyAgreementSigned'),
  { fallback: <Loading /> }
);
const SellCarBookTime = loadable(
  () => import('./App/views/SellCar/views/Flow/views/BookTime'),
  { fallback: <Loading /> }
);
const SellCarFindFacility = loadable(
  () => import('./App/views/SellCar/views/Flow/views/FindFacility'),
  { fallback: <Loading /> }
);
const SellFlowCarConfirmOrContinueCompany = loadable(
  () => import('./App/views/SellCar/views/ConfirmOrContinueCompany'),
  { fallback: <Loading /> }
);

const SellFlowCarThankYou = loadable(
  () => import('./App/views/SellCar/views/ThankYou'),
  { fallback: <Loading /> }
);

const SellCarTermsAndConditionsWithToken = loadable(
  () =>
    import(
      './App/views/SellCar/views/TermsAndConditions/SellFlowTermsAndConditionsWithToken'
    ),
  { fallback: <Loading /> }
);

const SellCarOwnerVerification = loadable(
  () => import('./App/views/SellCar/views/OwnerVerification'),
  { fallback: <Loading /> }
);

const SellCarSignin = loadable(
  () => import('./App/views/SellCar/views/Signin/SellFlowSignin'),
  { fallback: <Loading /> }
);

const Services = loadable(() => import('./App/views/Services'), {
  fallback: <Loading />
});

const ThankYouAgreementSigned = loadable(
  () => import('./App/shared/components/ThankYou/components/AgreementSigned'),
  { fallback: <Loading /> }
);
const Help = loadable(() => import('./App/views/Help'), {
  fallback: <Loading />
});

const Preview = loadable(() => import('./App/views/Preview'), {
  fallback: <Loading />
});

const Carguide = loadable(() => import('./App/views/Carguide'), {
  fallback: <Loading />
});
const CarguideWelcome = loadable(
  () => import('./App/views/Carguide/views/WelcomeToCarguide'),
  { fallback: <Loading /> }
);
const CarguideFlow = loadable(
  () => import('./App/views/Carguide/views/Question'),
  { fallback: <Loading /> }
);
const CarguideResult = loadable(
  () => import('./App/views/Carguide/views/Results'),
  { fallback: <Loading /> }
);

const TransferPickupTwoPaths = loadable(
  () => import('./App/views/Transfer/views/Pickup/TransferPickupTwoPaths'),
  { fallback: <Loading /> }
);
const TransferPickupForm = loadable(
  () => import('./App/views/Transfer/views/Pickup/views/Form'),
  { fallback: <Loading /> }
);
const TransferPickupSigning = loadable(
  () => import('./App/views/Transfer/views/Pickup/views/Signing'),
  { fallback: <Loading /> }
);
const TransferPickupThankYou = loadable(
  () => import('./App/views/Transfer/views/Pickup/views/ThankYou'),
  { fallback: <Loading /> }
);
const TransferPickupBookTime = loadable(
  () => import('./App/views/Transfer/views/Pickup/views/BookTime'),
  { fallback: <Loading /> }
);
const TransferPurchaseOwnership = loadable(
  () => import('./App/views/Transfer/views/Start'),
  { fallback: <Loading /> }
);
const TransferPurchaseOwnershipSigning = loadable(
  () => import('./App/views/Transfer/views/PurchaseOwnership/views/Signing'),
  { fallback: <Loading /> }
);
const TransferPurchaseOwnershipTwoPaths = loadable(
  () =>
    import(
      './App/views/Transfer/views/PurchaseOwnership/views/PurchaseOwnershipTwoPaths'
    ),
  { fallback: <Loading /> }
);
const TransferPurchaseOwnershipForm = loadable(
  () => import('./App/views/Transfer/views/PurchaseOwnership/views/Form'),
  { fallback: <Loading /> }
);
const TransferPurchaseOwnershipThankYou = loadable(
  () => import('./App/views/Transfer/views/PurchaseOwnership/views/ThankYou'),
  { fallback: <Loading /> }
);

const NewTransferPickupRight = loadable(
  () => import('./App/views/Transfer/views/New/PickupRight'),
  { fallback: <Loading /> }
);
const NewTransferPickupStatus = loadable(
  () =>
    import(
      './App/views/Transfer/views/New/PickupRight/views/TransferPickupStatus'
    ),
  { fallback: <Loading /> }
);
const NewTransferPurchaseRight = loadable(
  () => import('./App/views/Transfer/views/New/PurchaseRight'),
  { fallback: <Loading /> }
);
const NewTransferPurchaseStatus = loadable(
  () =>
    import(
      './App/views/Transfer/views/New/PurchaseRight/views/TransferPurchaseStatus'
    ),
  { fallback: <Loading /> }
);

const Payment = loadable(
  () => import('./App/views/Payment/views/PaymentDispatcher'),
  {
    fallback: <Loading />
  }
);

const Checkout = loadable(() => import('./App/views/Checkout'), {
  fallback: <Loading />
});

const CheckoutStart = loadable(
  () => import('./App/views/Checkout/views/CheckoutStart'),
  { fallback: <Loading /> }
);

const CheckoutConfirmation = loadable(
  () => import('./App/views/Checkout/views/CheckoutConfirmation'),
  { fallback: <Loading /> }
);

const BookPickupTimeWithProxy = loadable(
  () => import('./App/views/Checkout/views/BookPickupTimeWithProxy'),
  { fallback: <Loading /> }
);

const Unsubscribe = loadable(() => import('./App/views/Unsubscribe'), {
  fallback: <Loading />
});

const BlogMain = loadable(() => import('./App/views/Blog'), {
  fallback: <Loading />
});

const BlogCategory = loadable(() => import('./App/views/Blog/views/Category'), {
  fallback: <Loading />
});

const BlogArticle = loadable(() => import('./App/views/Blog/views/Article'), {
  fallback: <Loading />
});

const LinkGraveyard = loadable(() => import('./App/views/LinkGraveyard'), {
  fallback: <Loading />
});

const IframeDynamicPage = loadable(
  () => import('./App/views/Iframe/IframeDynamicPage'),
  {
    fallback: <Loading />
  }
);

const ValuationPage = loadable(() => import('./App/views/ValuationPage'), {
  fallback: <Loading />
});

const routeConf = (t: TranslateFunction) => [
  {
    path: '',
    exact: true,
    component: Start
  },
  {
    path: t('ROUTES.OTHER_SERVICES'),
    childRoutes: [{ path: t('ROUTES.VALUATION'), component: ValuationPage }]
  },
  {
    path: 'iframe/*',
    component: IframeDynamicPage
  },
  {
    path: 'social',
    component: VerifySocialLogin
  },
  {
    path: t('ROUTES.SERVICES'),
    component: Services
  },
  {
    path: t('ROUTES.SELL_HEAVY_EQUIPMENT'),
    component: SellHeavyEquipment
  },
  {
    path: t('ROUTES.SELL'),
    component: SellFlowCar,
    childRoutes: [
      {
        path: t('ROUTES.SELL_CAR_INFO'),
        component: SellCarInterestForm
      },
      {
        path: t('ROUTES.SELL_CAR_PROPOSAL'),
        childRoutes: [
          {
            path: ':token',
            childRoutes: [
              {
                path: ':baseObjectId',
                component: SellCarPreFilledInterestForm
              }
            ]
          }
        ]
      },
      {
        path: t('ROUTES.SELL_PRIVATE'),
        childRoutes: [
          {
            path: t('ROUTES.SELL_CAR_PROPOSAL'),
            childRoutes: [
              {
                path: ':token',
                childRoutes: [
                  {
                    path: ':baseObjectId',
                    component: SellCarPreFilledInterestForm
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: t('ROUTES.SELL_ORGANIZATION'),
        childRoutes: [
          {
            path: t('ROUTES.SELL_CAR_PROPOSAL'),
            childRoutes: [
              {
                path: ':token',
                childRoutes: [
                  {
                    path: ':baseObjectId',
                    component: SellCarPreFilledInterestForm
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: t('ROUTES.SELLFLOW_CONFIRM_OR_CONTINUE_COMPANY'),
        component: SellFlowCarConfirmOrContinueCompany
      },
      {
        path: `${t('ROUTES.SELL_RESERVATION_PRICE')}/:baseObjectId/:token`,
        component: ReservationPrice
      },
      {
        path: t('ROUTES.SELL_PRIVATE'),
        childRoutes: [
          {
            path: t('ROUTES.SELL_PRIVATE_FIND_FACILITY'),
            component: SellCarFindFacility,
            childRoutes: [
              {
                path: ':token/:processObjectId',
                component: SellCarFindFacility
              },
              {
                path: ':processObjectId',
                component: SellCarFindFacility
              }
            ]
          },
          {
            path: t('ROUTES.SELL_PRIVATE_BOOK_TIME'),
            component: SellCarBookTime,
            childRoutes: [
              {
                path: ':token/:processObjectId/:postalCode',
                component: SellCarBookTime
              }
            ]
          },
          {
            path: t('ROUTES.SELL_PRIVATE_COMPLETED'),
            component: SellCarDone
          },
          {
            path: t('ROUTES.SELL_AGREEMENT_SENT'),
            component: SellCarOwnerVerificationAgreementSent
          },
          {
            path: t('ROUTES.SELL_AGREEMENT_SIGNED'),
            component: SellCarPrivateAgreementSigned
          }
        ]
      },
      {
        path: t('ROUTES.SELL_ORGANIZATION'),
        childRoutes: [
          {
            path: t('ROUTES.SELL_ORGANIZATION_FIND_FACILITY'),
            component: SellCarFindFacility,
            childRoutes: [
              {
                path: ':token/:processObjectId',
                component: SellCarFindFacility
              }
            ]
          },
          {
            path: t('ROUTES.SELL_ORGANIZATION_BOOK_TIME'),
            component: SellCarBookTime,
            childRoutes: [
              {
                path: ':token/:processObjectId/:postalCode',
                component: SellCarBookTime
              }
            ]
          },
          {
            path: t('ROUTES.SELL_ORGANIZATION_COMPLETED'),
            component: SellCarDone
          },
          {
            path: t('ROUTES.SELL_AGREEMENT_SENT'),
            component: SellCarOwnerVerificationAgreementSent
          },
          {
            path: t('ROUTES.SELL_AGREEMENT_SIGNED'),
            component: SellCarCompanyAgreementSigned
          },
          {
            path: `${t('ROUTES.SELL_AGREEMENT_SIGNED')}/:baseObjectId/:token`,
            component: SellCarCompanyAgreementSigned
          }
        ]
      },
      {
        path: encodeURIComponent(t('ROUTES.THANK_YOU')),
        childRoutes: [
          {
            path: encodeURIComponent(t('ROUTES.THANK_YOU_MANUAL')),
            component: () => (
              <SellFlowCarThankYou contentKey="manualValuation" />
            )
          },
          {
            path: encodeURIComponent(t('ROUTES.THANK_YOU_CONSUMER')),
            component: () => <SellFlowCarThankYou contentKey="consumer" />
          },
          {
            path: encodeURIComponent(t('ROUTES.THANK_YOU_COMPANY')),
            component: () => <SellFlowCarThankYou contentKey="company" />
          },
          {
            path: encodeURIComponent(t('ROUTES.THANK_YOU_EXPRESS')),
            component: () => <SellFlowCarThankYou contentKey="express" />
          }
        ]
      },
      {
        path: encodeURIComponent(
          `${t('ROUTES.SELL_TERMS_AND_CONDITIONS')}/:id/:token`
        ),
        component: SellCarTermsAndConditionsWithToken
      },
      {
        path: `${t('ROUTES.SELL_OWNER_VERIFICATION')}/:baseObjectId/:token`,
        component: SellCarOwnerVerification
      },
      {
        path: `${t('ROUTES.SELL_OWNER_VERIFICATION')}/${t(
          'ROUTES.SELL_SIGN_IN'
        )}`,
        component: SellCarSignin
      },
      {
        path: `${t('ROUTES.SELL_OWNER_VERIFICATION')}/${t(
          'ROUTES.SELL_AGREEMENT_SIGNED'
        )}`,
        component: SellCarOwnerVerificationAgreementSigned
      },
      {
        path: encodeURIComponent(t('ROUTES.SELL_SIGN_IN')),
        component: () => <SellCarSignin />
      }
    ]
  },
  {
    path: t('ROUTES.AUCTIONS'),
    component: RedirectFromAuctions,
    exact: true
  },
  {
    path: t('ROUTES.FIXED_PRICE'),
    component: RedirectFromFixedPrice,
    exact: true
  },
  {
    path: t('ROUTES.FILTER_ALL_VEHICLES'),
    component: FilterPageAllVehicles,
    exact: true
  },
  {
    path: t('ROUTES.FILTER_CAR'),
    component: FilterPageCar,
    exact: true,
    childRoutes: [
      {
        path: `:carBrandOrCategorySlug`,
        component: CategoryPageCar,
        exact: false
      },
      {
        path: `:carBrandSlug/:carFamilySlug`,
        component: FamilyPageCar,
        exact: false
      }
    ]
  },
  {
    path: t('ROUTES.FILTER_RECREATIONAL_VEHICLE'),
    component: FilterPageRecreationalVehicle,
    exact: true,
    childRoutes: [
      {
        path: `:carBrandOrCategorySlug`,
        component: CategoryPageRecreationalVehicle,
        exact: false
      }
    ]
  },
  {
    path: t('ROUTES.FILTER_LIGHT_TRANSPORT'),
    component: FilterPageLightTransport,
    exact: true,
    childRoutes: [
      {
        path: `:carBrandOrCategorySlug`,
        component: CategoryPageLightTransport,
        exact: false
      }
    ]
  },
  {
    path: t('ROUTES.FILTER_HEAVY_TRANSPORT'),
    component: FilterPageHeavyTransport,
    exact: true,
    childRoutes: [
      {
        path: `:carBrandOrCategorySlug`,
        component: CategoryPageHeavyTransport,
        exact: false
      }
    ]
  },
  {
    path: t('ROUTES.FILTER_MACHINE'),
    component: FilterPageMachines,
    exact: true,
    childRoutes: [
      {
        path: `:carBrandOrCategorySlug`,
        component: CategoryPageMachines,
        exact: false
      }
    ]
  },
  {
    path: `${t('ROUTES.FIXED_PRICE')}/:slug`,
    component: ObjectPage,
    exact: false,
    childRoutes: [
      {
        path: t('ROUTES.GALLERY'),
        component: GalleryPage,
        exact: true
      }
    ]
  },
  {
    path: `${t('ROUTES.AUCTIONS')}/:slug`,
    component: ObjectPage,
    exact: false,
    childRoutes: [
      {
        path: t('ROUTES.GALLERY'),
        component: GalleryPage,
        exact: true
      }
    ]
  },
  {
    path: `${t('ROUTES.BOOK_PICKUP_APPOINTMENT')}/:deliveryId/:token`,
    component: BookPickupAppointment,
    exact: true
  },
  {
    path: t('ROUTES.MY_ACCOUNT'),
    component: Account,
    routes: [
      {
        path: t('ROUTES.MY_ACCOUNT_SAVED_OBJECTS'),
        component: AccountSavedObjects
      },
      {
        path: t('ROUTES.MY_ACCOUNT_SAVED_SEARCHES'),
        component: AccountSavedSearches
      },
      {
        path: t('ROUTES.MY_ACCOUNT_MY_AUCTIONS'),
        component: AccountMyAuctions,
        routes: [
          {
            path: '',
            component: AccountMyAuctionsActive
          },
          {
            // Fallback for old link structure
            path: t('ROUTES.MY_ACCOUNT_MY_AUCTIONS_OPEN'),
            component: AccountMyAuctionsActive
          },
          {
            path: t('ROUTES.MY_ACCOUNT_MY_AUCTIONS_WON'),
            component: AccountMyAuctionsCompleted
          },
          {
            path: t('ROUTES.MY_ACCOUNT_MY_AUCTIONS_LOST'),
            component: AccountMyAuctionsClosed
          }
        ]
      },
      {
        path: t('ROUTES.MY_ACCOUNT_MY_SALES'),
        component: AccountMySales,
        routes: [
          {
            path: '',
            component: AccountMySalesCurrentSales
          },
          {
            // Fallback for old link structure
            path: t('ROUTES.MY_ACCOUNT_MY_SALES_CURRENT_SALES'),
            component: AccountMySalesCurrentSales
          },
          {
            path: `${t('ROUTES.MY_ACCOUNT_MY_SALES_CURRENT_SALES')}/:id`,
            component: AccountMySalesCurrentSaleDetails
          },
          {
            path: t('ROUTES.MY_ACCOUNT_MY_SALES_BUSINESS_PROPOSALS'),
            component: AccountMySalesBusinessProposals
          }
        ]
      },
      {
        path: t('ROUTES.MY_ACCOUNT_PARTNERSHIP_PAGE'),
        component: PartnershipPage
      },
      {
        path: t('ROUTES.MY_ACCOUNT_SETTINGS'),
        component: AccountSettings,
        routes: [
          {
            path: '',
            component: AccountSettingsDetails
          },
          {
            // Fallback for old link structure
            path: t('ROUTES.MY_ACCOUNT_SETTINGS_DETAILS'),
            component: AccountSettingsDetails
          },
          {
            path: t('ROUTES.MY_ACCOUNT_SETTINGS_CHANGE_PASSWORD'),
            component: AccountSettingsPassword
          }
        ]
      }
    ]
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_SIGN_IN')}`,
    component: Login
  },
  {
    path: `${t('ROUTES.LOGOUT')}`,
    component: Logout
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_VERIFY_EMAIL')}`,
    component: EmailVerification
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_VERIFY')}`,
    component: Verification
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_VERIFY_ORGANIZATION')}`,
    component: OrganizationVerification
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_REGISTER_PRIVATE')}`,
    component: PrivateRegistration
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_REGISTER_ORGANIZATION')}`,
    component: CompanyRegistration
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_FORGOT_PASSWORD')}`,
    component: ForgotPassword
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_CREATE_PASSWORD')}`,
    component: CreatePassword
  },
  {
    path: `${t('ROUTES.MEMBER')}/${t('ROUTES.MEMBER_RESET_PASSWORD')}`,
    component: ResetPassword
  },
  {
    path: 'preview/:id',
    component: Preview
  },
  {
    path: `${t('ROUTES.CUSTOMER_SERVICE')}/${t(
      'ROUTES.CUSTOMER_SERVICE_FACILITIES'
    )}`,
    component: Facilities
  },
  {
    path: `${t('ROUTES.CUSTOMER_SERVICE')}/${t(
      'ROUTES.CUSTOMER_SERVICE_FACILITIES'
    )}/:slug`,
    component: Facility
  },
  {
    path: t('ROUTES.CARGUIDE'),
    component: Carguide,
    routes: [
      {
        path: t('ROUTES.CARGUIDE_START'),
        component: CarguideWelcome
      },
      {
        path: t('ROUTES.CARGUIDE_FLOW'),
        component: CarguideFlow
      },
      {
        path: t('ROUTES.CARGUIDE_RESULT'),
        component: CarguideResult
      }
    ]
  },
  {
    path: t('ROUTES.CHECKOUT'),
    component: CheckoutStart,
    childRoutes: [
      {
        path: `${t('ROUTES.BOOK_PICKUP_TIME_WITH_PROXY')}/:orderId/:token`,
        component: BookPickupTimeWithProxy
      },
      {
        path: `${t('ROUTES.CHECKOUT_START')}/:auctionId/:checkoutType`,
        component: CheckoutStart
      },
      {
        path: `${t('ROUTES.CHECKOUT_FLOW')}/:orderId`,
        component: Checkout
      },
      {
        path: `${t('ROUTES.CHECKOUT_CONFIRMATION')}/:orderId`,
        component: CheckoutConfirmation
      }
    ]
  },
  {
    path: `${t('ROUTES.THANK_YOU')}/${t('ROUTES.THANK_YOU_AGREEMENT')}`,
    component: ThankYouAgreementSigned
  },
  {
    path: `${t('ROUTES.TRANSFER_PURCHASE_OWNERSHIP')}/${t(
      'ROUTES.TRANSFER_TWO_PATHS'
    )}/:deliveryId/:token`,
    component: TransferPurchaseOwnershipTwoPaths
  },
  {
    path: `${t('ROUTES.TRANSFER_PURCHASE_OWNERSHIP')}/${t(
      'ROUTES.TRANSFER_FORM'
    )}/:deliveryId/:token`,
    component: TransferPurchaseOwnershipForm
  },
  {
    path: `${t('ROUTES.TRANSFER_PURCHASE_OWNERSHIP')}/${t(
      'ROUTES.TRANSFER_SIGNING'
    )}/:deliveryId/:token`,
    component: TransferPurchaseOwnershipSigning
  },
  {
    path: `${t('ROUTES.TRANSFER_PURCHASE_OWNERSHIP')}/${t(
      'ROUTES.TRANSFER_THANK_YOU'
    )}/:deliveryId/:token`,
    component: TransferPurchaseOwnershipThankYou
  },
  {
    path: `${t('ROUTES.TRANSFER_PURCHASE_OWNERSHIP')}/:deliveryId/:token`,
    component: TransferPurchaseOwnership
  },
  {
    path: `${t('ROUTES.TRANSFER_PICKUP')}/${t(
      'ROUTES.TRANSFER_BOOK_TIME'
    )}/:deliveryId/:token`,
    component: TransferPickupBookTime
  },
  {
    path: `${t('ROUTES.TRANSFER_PICKUP')}/${t(
      'ROUTES.TRANSFER_FORM'
    )}/:deliveryId/:token`,
    component: TransferPickupForm
  },
  {
    path: `${t('ROUTES.TRANSFER_PICKUP')}/${t(
      'ROUTES.TRANSFER_THANK_YOU'
    )}/:deliveryId/:token`,
    component: TransferPickupThankYou
  },
  {
    path: `${t('ROUTES.TRANSFER_PICKUP')}/${t(
      'ROUTES.TRANSFER_SIGNING'
    )}/:deliveryId/:token`,
    component: TransferPickupSigning
  },
  {
    path: `${t('ROUTES.TRANSFER_PICKUP')}/:deliveryId/:token`,
    component: TransferPickupTwoPaths
  },
  {
    path: `${t('ROUTES.TRANSFER_PICKUP_RIGHT')}/:deliveryId/:token`,
    component: NewTransferPickupRight,
    childRoutes: [
      {
        path: `status/:deliveryTransferId`,
        component: NewTransferPickupStatus
      }
    ]
  },
  {
    path: `${t('ROUTES.TRANSFER_PURCHASE_RIGHT')}/:deliveryId/:token`,
    component: NewTransferPurchaseRight,
    childRoutes: [
      {
        path: `status/:deliveryTransferId`,
        component: NewTransferPurchaseStatus
      }
    ]
  },
  {
    path: `${t('ROUTES.NOTIFICATION_UNSUBSCRIBE')}`,
    component: Unsubscribe
  },
  {
    path: `${t('ROUTES.PAYMENT')}/:token`,
    component: Payment
  },
  {
    path: t('ROUTES.HELP'),
    component: Help,
    childRoutes: [
      {
        path: `:question`,
        component: Help
      }
    ]
  },
  {
    path: `${t('ROUTES.IMPERSONATE')}/:token`,
    component: Impersonate
  },
  {
    path: t('ROUTES.PARTNERSHIP_PAGE'),
    component: PartnershipPage
  },
  {
    path: `${t('ROUTES.BLOG')}`,
    component: BlogMain
  },
  {
    path: `${t('ROUTES.BLOG')}/:categorySlug`,
    component: BlogCategory
  },
  {
    path: `${t('ROUTES.BLOG')}/:categorySlug/:articleSlug`,
    component: BlogArticle
  },
  {
    path: `${t('ROUTES.GRAVEYARD')}`,
    component: LinkGraveyard
  },
  {
    path: '*',
    component: DynamicPage
  }
];

export const getNonTranlatedRouteConf = () => {
  const fakeTranslate = (string: string) => string;
  return routeConf(fakeTranslate);
};

export const buildAllRoutesWithNested = ({
  routes,
  basePath = '/'
}: {
  routes: RouteConfig[];
  basePath?: string;
}): { path: string }[] => {
  let builtRoutes: { path: string }[] = [];

  routes.forEach(route => {
    const {
      path: originPath = '',
      routes: subRoutes = [],
      childRoutes: nestedRoutes = [],
      component
    } = route;
    const path = `${basePath}${decodeURIComponent(originPath)}`;

    component &&
      builtRoutes.push({
        path
      });

    [nestedRoutes, subRoutes].forEach(routes => {
      if (routes?.length) {
        builtRoutes = [
          ...builtRoutes,
          ...buildAllRoutesWithNested({
            routes,
            basePath: `${path}/`
          })
        ];
      }
    });
  });

  return builtRoutes;
};

/*
Function for constucting routes from config

`routes` is a react-router-config specific thing,
which basically means routes that should be rendered inside another route.
For instance for tabs where each tab contains a route.
`childRoutes` is simply routes that share the same parent path.
So instead of specifying routes in a flat structure like:
{ path: "a", component: AComponent }
{ path: "a/some_path", component: SomeComponent }
{ path: "a/some_other_path", component: SomeComponent }
We can specify that like:
[{
  path: "/a",
  component: AComponent,
  childRoutes: [
    { path: "some_path", component: SomeComponent }
    { path: "some_other_path", component: SomeComponent }
  ]
}]
*/

type BuildRoutesProps = {
  routes: RouteConfig[];
  basePath?: string;
  t: TranslateFunction;
};

export const buildRoutes = ({
  routes,
  basePath = '/',
  t
}: BuildRoutesProps): RouteConfig[] => {
  let builtRoutes: RouteConfig[] = [];

  routes.forEach(route => {
    const {
      path: originPath = '',
      routes: subRoutes = [],
      childRoutes: nestedRoutes = [],
      exact,
      ...restRoute
    } = route;
    const path = `${basePath}${decodeURIComponent(originPath)}`;
    const hasSubRoutes = subRoutes.length > 0;

    builtRoutes.push({
      ...restRoute,
      path: translateRoute(t, path),
      nonTranslatedPath: path,
      exact: exact || !hasSubRoutes, // set exact to true if the route does not have sub-routes
      routes: hasSubRoutes
        ? buildRoutes({ routes: subRoutes, basePath: `${path}/`, t })
        : undefined
    });

    if (nestedRoutes?.length) {
      builtRoutes = [
        ...builtRoutes,
        ...buildRoutes({ routes: nestedRoutes, basePath: `${path}/`, t })
      ];
    }
  });

  return builtRoutes;
};

const buildRoutesFunction = (t: TranslateFunction) =>
  buildRoutes({ routes: getNonTranlatedRouteConf(), t });

export default buildRoutesFunction;
