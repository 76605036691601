import { googleRecaptchaSiteKey } from '~/config/public/environment';
import { requestErrorHandler } from './notifyError';

export const runWithRecaptcha = (func: (googleResponse: string) => void) => {
  if (
    typeof window !== 'undefined' &&
    typeof window.grecaptcha !== 'undefined'
  ) {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(googleRecaptchaSiteKey, {
          action: 'submit'
        })
        .then(googleResponse => {
          func(googleResponse);
        })
        .catch(requestErrorHandler);
    });
  } else {
    console.error(new Error('No recaptcha enabled.'));
  }
};
