import {
  DynamicPageQuery,
  DynamicPageModelBodyField
} from '~/config/generated/graphql';
import { Root, Block } from 'datocms-structured-text-utils';

export const handleDynamicPage = ({ dynamicPage }: DynamicPageQuery) => {
  if (!dynamicPage) {
    return null;
  }
  const epmtyDocumentChildren = [
    { type: 'paragraph', children: [{ type: 'span', value: '' }] }
  ];
  const valueChildren: Root['children'] =
    dynamicPage?.body?.value?.document?.children ?? [];
  const textSectionIds: string[] =
    dynamicPage?.body?.blocks
      ?.map(block => block?.__typename === 'TextSectionRecord' && block?.id)
      .filter(Boolean) ?? [];

  /*
  Initially, you could only use a TextSection block inside the "body" field and place the page content there, 
  but the content managers wanted to be able to write content immediately for one section page.
  */
  const singleSectionBodyChildren = valueChildren.filter(
    (child: Block) => !textSectionIds?.includes(child?.item)
  );
  const singleSectionBody = {
    __typename: dynamicPage?.body?.__typename,
    value: {
      schema: 'dast',
      document: {
        type: 'root',
        children:
          singleSectionBodyChildren.length > 0
            ? singleSectionBodyChildren
            : epmtyDocumentChildren
      }
    },
    blocks: dynamicPage?.body?.blocks?.filter(
      block => !textSectionIds?.includes(block?.id)
    ),
    links: dynamicPage?.body?.links
  } as DynamicPageModelBodyField;

  const multiSectionBodyChildren = valueChildren.filter((child: Block) =>
    textSectionIds?.includes(child?.item)
  );
  const multiSectionBody = {
    __typename: dynamicPage?.body?.__typename,
    value: {
      schema: 'dast',
      document: {
        type: 'root',
        children:
          multiSectionBodyChildren.length > 0
            ? multiSectionBodyChildren
            : epmtyDocumentChildren
      }
    },
    blocks: dynamicPage?.body?.blocks?.filter(block =>
      textSectionIds?.includes(block?.id)
    ),
    links: [] as DynamicPageModelBodyField['links']
  } as DynamicPageModelBodyField;
  return {
    hero: dynamicPage?.hero,
    seo: dynamicPage?.seo,
    singleSectionBody,
    multiSectionBody,
    bodyStyles: {
      backgroundColor: dynamicPage?.bodyBackgroundColor,
      paddingTop: dynamicPage?.bodyPaddingTop,
      paddingBottom: dynamicPage?.bodyPaddingBottom,
      contentMaxWidth: dynamicPage?.bodyContentMaxWidth,
      contentBackgroundColor: dynamicPage?.bodyContentBackgroundColor,
      contentPadding: dynamicPage?.bodyContentPadding,
      contentTextAlign: dynamicPage?.bodyContentTextAlign,
      contentBorderColor: dynamicPage?.bodyContentBorderColor,
      innerContentMaxWidth: dynamicPage?.bodyInnerContentMaxWidth
    },
    kvdHelperUrl: dynamicPage._editingUrl
  };
};
