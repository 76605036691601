import qs from 'qs';
import {
  GetSizedUrlProps,
  SizeParams,
  ImageParams,
  MediaImageType,
  MediaSize
} from './types';
import { mediaSizes } from './constants';

export const getSizedUrl = (baseUrl = '', options: GetSizedUrlProps) => {
  if (typeof baseUrl !== 'string') {
    return '';
  }

  if (
    baseUrl &&
    !baseUrl.includes('imgix') &&
    !baseUrl.includes('ctfassets') &&
    !baseUrl.includes('datocms')
  ) {
    return baseUrl;
  }

  const { quality = 75, imageType, size, dpr } = options;

  const params: SizeParams = {
    q: quality,
    w: mediaSizes[imageType][size],
    auto: 'format,compress', // Enable automatic format selection and compression
    cs: 'srgb', // Color space optimization
    fit: 'clip',
    fm: 'webp' // Prefer WebP format when supported
  };

  if (dpr) {
    params.dpr = dpr;

    // Adjust quality based on DPR to balance file size
    if (dpr > 1) {
      params.q = Math.max(quality - (dpr - 1) * 20, 20);
    }
  }

  const paramString = qs.stringify({ ...params, ...options.imageParams });
  return `${baseUrl}?${paramString}`;
};

export const generateMediaSizes = () => {
  return '(max-width: 320px) 320px, (max-width: 545px) 490px, (max-width: 1024px) 710px, (max-width: 1200px) 1024px, (max-width: 1560px) 1200px, (max-width: 1920px) 1560px, 1920px'.trim();
};

export const generateSrcSet = (
  src: string,
  imageType: MediaImageType,
  sizes: readonly MediaSize[],
  quality?: number,
  imageParams?: ImageParams,
  dpr?: number
) => {
  return sizes
    .map(size => {
      const width = mediaSizes[imageType][size];
      const url = getSizedUrl(src, {
        imageType,
        size,
        quality,
        imageParams,
        dpr
      });
      return `${url} ${width}w`;
    })
    .join(', ');
};
