import React from 'react';
import { Path } from 'react-hook-form';
import { regexNationalIdentificationNumber } from '~/helpers/regex';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { TextFieldProps } from '../types';
import { NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH } from '~/App/shared/validation/constants';
import { CountryOption } from '~/helpers/countries';

interface NationalIdentificationFieldProps<FormState>
  extends TextFieldProps<FormState> {
  countryValue?: CountryOption['value'];
}

const NationalIdentificationField = <FormState,>({
  control,
  t,
  name,
  rules,
  countryValue = 'se',
  ...props
}: NationalIdentificationFieldProps<FormState>) => {
  const validationRules = {
    required: {
      value: true,
      message: t(`National identification number can't be blank`)
    },
    maxLength: {
      value: NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH,
      message: t(
        'National identification number is too long (maximum is %s characters)',
        NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH
      )
    },
    pattern:
      countryValue === 'se'
        ? {
            value: regexNationalIdentificationNumber,
            message: t('National identification number is invalid')
          }
        : undefined,
    ...rules
  };

  const fieldName = name ?? ('nationalIdentificationNumber' as Path<FormState>);

  return (
    <TextFieldWithControl
      control={control}
      name={fieldName}
      label={t('National identification number')}
      rules={validationRules}
      type="tel"
      {...props}
    />
  );
};

export default NationalIdentificationField;
