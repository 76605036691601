import { useState, useEffect } from 'react';

/**
 * Hook to get the current device pixel ratio and listen for changes
 * @returns The current device pixel ratio
 */

export function useDevicePixelRatio(): number {
  const [dpr, setDpr] = useState<number>(() =>
    typeof window !== 'undefined' ? window.devicePixelRatio : 1
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const updateDpr = () => {
      setDpr(window.devicePixelRatio);
    };

    // Some browsers support matchMedia for detecting DPR changes
    const mediaQueryLists = [
      window.matchMedia('(resolution: 1dppx)'),
      window.matchMedia('(resolution: 2dppx)'),
      window.matchMedia('(resolution: 3dppx)')
    ];

    mediaQueryLists.forEach(mql => {
      if (mql?.addEventListener) {
        mql.addEventListener('change', updateDpr);
      } else if (mql?.addListener) {
        // Older browsers
        mql.addListener(updateDpr);
      }
    });

    return () => {
      mediaQueryLists.forEach(mql => {
        if (mql.removeEventListener) {
          mql.removeEventListener('change', updateDpr);
        } else if (mql.removeListener) {
          // Older browsers
          mql.removeListener(updateDpr);
        }
      });
    };
  }, []);

  return dpr;
}
