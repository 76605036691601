import { sessionStart } from '~/App/shared/actions/session';
import { ReduxDispatch } from '~/App/shared/interfaces/Redux';
import store from '~/config/store';
import {
  getGAUserFromState,
  trackGA4Login
} from '~/helpers/client/ga4TrackEvent';
import {
  SessionErrors,
  SessionError,
  ErrorTuple,
  Session
} from '~/App/shared/interfaces/store/Session';
import { TranslateFunction } from '~/Locale';

export const handleSessionErrors = (
  t: TranslateFunction,
  errors?: SessionErrors
) => {
  const error = errors?.find(
    (error: SessionError | ErrorTuple | string): error is SessionError =>
      typeof error !== 'string' && 'status' in error
  );

  if (error) {
    if (error.status >= 400 && error.status < 500) {
      return t('Incorrect username or password.');
    }
    if (error.status >= 500 && error.status < 600) {
      return t('Something went wrong, please try again later.');
    }
  }

  // default to generic error message
  return t('Something went wrong, please try again later.');
};

interface StartSessionArgs {
  dispatch: ReduxDispatch;
  credentials?: {
    email: string;
    password: string;
  };
  onSignIn?(): void;
  onFail?(error: string): void;
  t: TranslateFunction;
  session: Session;
}

export const startSession = async ({
  dispatch,
  onFail,
  onSignIn,
  t,
  session
}: StartSessionArgs) => {
  const data = await dispatch(
    sessionStart({
      session
    })
  );

  if (data?.errors) {
    const errorMessage = handleSessionErrors(t, data.errors);
    onFail?.(errorMessage);
    return;
  }

  if (data?.isAuthenticated) {
    if (store) {
      trackGA4Login(
        { login_type: 'bankid_login' },
        getGAUserFromState(store.getState())
      );
    }

    onSignIn?.();
  }
};
