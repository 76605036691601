import { ValuationPageQuery } from '~/config/generated/graphql';

export const handleValuationPage = ({ valuationPage }: ValuationPageQuery) => {
  return {
    hero: {
      image: valuationPage?.image,
      heading: valuationPage?.heading,
      subheading: valuationPage?.subheading,
      actionBoxTitle: valuationPage?.actionBoxTitle,
      button: valuationPage?.button
    },
    kvdHelperUrl: valuationPage?._editingUrl,
    faq: valuationPage?.faq,
    largeFeatureCard: valuationPage?.largeFeatureCard,
    smallFeatureCards: valuationPage?.smallFeatureCards,
    trustPilotTitle: valuationPage?.trustpilotTitle,
    seoInfo: valuationPage?.seoInformation,
    largeFeatureCards: valuationPage?.largeFeatureCards,
    seo: valuationPage?.seo
  };
};
