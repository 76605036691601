import { SubscriptionType } from '../interfaces/EmailSubscription';

type CreateEmailSubscriptionNames = {
  personalCarSubscription: boolean;
  heavyVehiclesSubscription: boolean;
  corporateSubscription?: boolean;
  carDealerSubscription?: boolean;
};

export function createEmailSubscriptionNames({
  personalCarSubscription,
  heavyVehiclesSubscription,
  corporateSubscription,
  carDealerSubscription
}: CreateEmailSubscriptionNames) {
  const emailSubscriptionNames: SubscriptionType[] = [];

  personalCarSubscription &&
    emailSubscriptionNames.push(SubscriptionType.WeeklyPersonalCar);
  heavyVehiclesSubscription &&
    emailSubscriptionNames.push(SubscriptionType.WeeklyHeavyVehicles);
  corporateSubscription &&
    emailSubscriptionNames.push(SubscriptionType.Corporate);
  carDealerSubscription &&
    emailSubscriptionNames.push(SubscriptionType.CarDealer);

  return emailSubscriptionNames;
}
