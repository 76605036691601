/* eslint-disable max-lines */
import dayjs from 'dayjs';
import {
  checkoutSubmit,
  fetchCheckoutPrices,
  FetchCheckoutPricesInput,
  getCheckoutOrderByAuctionId,
  getHomeDeliveryCost,
  getLoanCost,
  getOrder,
  getTransportCosts,
  startCheckoutOrder,
  updateCheckoutBuyer,
  updateCheckoutDelivery,
  updateCheckoutFinancing,
  updateCheckoutPickupBy,
  updateCheckoutConfigureCarDealer,
  updatePickupProxy,
  CheckoutError,
  SetPickupPersonCarDealerInput,
  updateCheckoutHeavyEquipmentFinancing
} from '~/helpers/orchestration/checkout';
import { ReduxStore } from '../interfaces/store';
import {
  idToken as idTokenSelector,
  member as memberSelector,
  memberIdSelector
} from '../selectors/sessionSelector';
import {
  checkoutDeliveryLocation,
  checkoutFacilityId,
  checkoutOrderId,
  checkoutPricesSelector,
  checkoutProcessObject,
  checkoutToFacilityId,
  checkoutType,
  checkoutWarrantyMonths,
  financingRoot,
  checkoutPaymentPeriod,
  pickupLocation as pickupLocationSelector,
  checkoutSelectedServices,
  warrantyChoicesSelector,
  checkoutCurrentFacility,
  interestedInExtraTires,
  checkoutOrderAuthToken,
  orderGA4ObjectSelector
} from '../selectors/checkoutSelector';
import { CheckoutType } from '../types/CheckoutType';
import { DeliveryLocation } from '../interfaces/DeliveryLocation';
import { DEFAULT_ERROR_MESSAGE } from '~/config/constants';
import { Snack } from '../interfaces/store/Snackbar';
import { ADD_SNACK } from './snackbarActions';
import Auction, { AuctionType } from '~/App/shared/interfaces/Auction';
import { TimePickerSlot } from '~/App/views/Checkout/components/Delivery/PickupTime';
import { getCheckoutStepState } from '~/helpers/checkoutPure';
import { ReduxDispatch } from '../interfaces/Redux';
import { CheckoutBuyer } from '../interfaces/store/Checkout';
import {
  PICKUP_LOCATIONS,
  PICKUP_PERSON,
  WarrantyMonths
} from '~/App/views/Checkout/constants';
import {
  getAvailablePickupTimeslots,
  GetAvailablePickupTimeslotsResponse
} from '~/helpers/orchestration/checkoutPickupProxy';
import { getAllowedResidualLoan } from '~/helpers/calculator';
import { TranslateFunction } from '~/Locale';
import {
  GA4ObjectProperties,
  ga4TrackCheckoutFlow,
  getGAUserFromState,
  objectPropertiesFromAuction
} from '~/helpers/client/ga4TrackEvent';
import { getAuction } from '~/helpers/orchestration/auctions';
import { failedToFetch } from './connection-events';
import { isAxiosError } from '~/helpers/axiosUtils';

export type PaymentMethod = 'CASH' | 'LOAN';
export type CheckoutService = 'WARRANTY' | 'SERVICE_AGREEMENT' | 'INSURANCE';

export const TOGGLE_CHECKOUT_SERVICE = 'TOGGLE_CHECKOUT_SERVICE';
export const toggleSelectedService = (payload: CheckoutService) => ({
  type: TOGGLE_CHECKOUT_SERVICE,
  payload
});

export const INCLUDE_CHECKOUT_SERVICE = 'INCLUDE_CHECKOUT_SERVICE';
export const includeSelectedService = (payload: CheckoutService) => ({
  type: INCLUDE_CHECKOUT_SERVICE,
  payload
});

export const EXCLUDE_CHECKOUT_SERVICE = 'EXCLUDE_CHECKOUT_SERVICE';
export const excludeSelectedService = (payload: CheckoutService) => ({
  type: EXCLUDE_CHECKOUT_SERVICE,
  payload
});

export const removeSelectedServices = () => ({
  type: 'REMOVE_CHECKOUT_SERVICES'
});

export const NEXT_STEP = 'checkout/NEXT_STEP';
export const nextCheckoutStep = () => ({
  type: NEXT_STEP
});

export const GOTO_STEP = 'checkout/GOTO_STEP';
export const gotoCheckoutStep = (payload: number) => ({
  type: GOTO_STEP,
  payload
});

export const SET_WARRANTY_PERIOD = 'checkout/SET_WARRANTY_PERIOD';
export const setWarrantyPeriod = (payload: WarrantyMonths) => ({
  type: SET_WARRANTY_PERIOD,
  payload
});

export const SET_PAYMENT_PERIOD = 'checkout/SET_PAYMENT_PERIOD';
export const setPaymentPeriod = (payload: string) => ({
  type: SET_PAYMENT_PERIOD,
  payload
});

export const SET_DOWN_PAYMENT = 'checkout/SET_DOWN_PAYMENT';
export const setDownPayment = (payload: string) => ({
  type: SET_DOWN_PAYMENT,
  payload
});

export const SET_PICKUP_PROXY_SELECTED = 'SET_PICKUP_PROXY_SELECTED';
export const setPickupProxySelected = (pickupProxySelected: boolean) => ({
  type: SET_PICKUP_PROXY_SELECTED,
  pickupProxySelected
});

export const SET_PICKUP_PERSON_REQUEST = 'SET_PICKUP_PERSON_REQUEST';
export const SET_PICKUP_PERSON_SUCCESS = 'SET_PICKUP_PERSON_SUCCESS';
export const SET_PICKUP_PERSON_FAILURE = 'SET_PICKUP_PERSON_FAILURE';
export const setPickupPerson =
  (payload: PICKUP_PERSON) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);

    dispatch({ type: SET_PICKUP_PERSON_REQUEST, payload });
    await updateCheckoutPickupBy(orderId, idToken, payload === PICKUP_PERSON.ME)
      .then(() => {
        dispatch({
          type: SET_PICKUP_PERSON_SUCCESS,
          payload
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: SET_PICKUP_PERSON_FAILURE,
          payload: err
        });
      });
  };

export const SET_PICKUP_PERSON_CAR_DEALER_REQUEST =
  'SET_PICKUP_PERSON_CAR_DEALER_REQUEST';
export const SET_PICKUP_PERSON_CAR_DEALER_SUCCESS =
  'SET_PICKUP_PERSON_CAR_DEALER_SUCCESS';
export const SET_PICKUP_PERSON_CAR_DEALER_FAILURE = 'SET_PICKUP_PERSON_FAILURE';

export const setPickupPersonCarDealer =
  (payload: SetPickupPersonCarDealerInput) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);
    const authToken = checkoutOrderAuthToken(state);

    dispatch({
      type: SET_PICKUP_PERSON_CAR_DEALER_REQUEST,
      payload
    });
    await updatePickupProxy(orderId, idToken, { ...payload, authToken })
      .then(res => {
        dispatch({
          type: SET_PICKUP_PERSON_CAR_DEALER_SUCCESS,
          pickupProxy: res?.data?.order?.pickupProxy
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: SET_PICKUP_PERSON_FAILURE,
          payload: err
        });
      });
  };

export const SET_SELECTED_TIMESLOT = 'SET_SELECTED_TIMESLOT';
export const setSelectedTimeslot = (timeslot?: TimePickerSlot) => ({
  type: 'SET_SELECTED_TIMESLOT' as const,
  timeslot
});

export type SetSelectedTimeslotAction = ReturnType<typeof setSelectedTimeslot>;

export const SET_PICKUP_LOCATION = 'checkout/SET_PICKUP_LOCATION';
export const setPickupLocation = (payload: PICKUP_LOCATIONS) => ({
  type: SET_PICKUP_LOCATION,
  payload
});

export const SET_TO_FACILITY_ID = 'checkout/SET_TO_FACILITY_ID';
export const setToFacilityId = (payload: string) => ({
  type: SET_TO_FACILITY_ID,
  payload
});

export const RESET_CHECKOUT_STATE = 'RESET_CHECKOUT_STATE';
export const resetCheckoutState = () => ({
  type: RESET_CHECKOUT_STATE
});

export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';
export const changePaymentMethod = (payload: PaymentMethod) => ({
  type: CHANGE_PAYMENT_METHOD,
  payload
});

export const changeInterestInExtraTires = (value: boolean) => ({
  type: 'CHANGE_INTEREST_IN_EXTRA_TIRES' as const,
  value
});

export type ChangeInterestInExtraTiresAction = ReturnType<
  typeof changeInterestInExtraTires
>;

export const SET_ORDER_GA4_OBJECT_REQUEST = 'SET_ORDER_GA4_OBJECT_REQUEST';
export const setOrderGA4ObjectRequest = () => ({
  type: SET_ORDER_GA4_OBJECT_REQUEST
});

export const SET_ORDER_GA4_OBJECT_SUCCESS = 'SET_ORDER_GA4_OBJECT_SUCCESS';
export const setOrderGA4ObjectSuccess = (payload: GA4ObjectProperties) => ({
  type: SET_ORDER_GA4_OBJECT_SUCCESS,
  payload
});

export const SET_ORDER_GA4_OBJECT_FAILURE = 'SET_ORDER_GA4_OBJECT_FAILURE';
export const setOrderGA4ObjectFailure = () => ({
  type: 'SET_ORDER_GA4_OBJECT_FAILURE'
});

export const CLEAR_ORDER_GA4_OBJECT = 'CLEAR_ORDER_GA4_OBJECT';
export const clearOrderGa4Object = () => ({
  type: CLEAR_ORDER_GA4_OBJECT
});

export const setOrderGA4Object =
  (auctionId: string, authToken?: string) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const memberId = memberIdSelector(state);

    dispatch(setOrderGA4ObjectRequest());

    try {
      const auction = await getAuction({
        id: auctionId,
        idToken: authToken,
        params: {
          memberId
        }
      });

      // Must be of interface Auction not AuctionNotFound
      if ('auctionType' in (auction as Auction)) {
        const ga4Object = objectPropertiesFromAuction(auction as Auction);
        // cache the auction object for GA4
        return dispatch(setOrderGA4ObjectSuccess(ga4Object));
      }
    } catch (error: unknown) {
      dispatch(failedToFetch());
      return dispatch(setOrderGA4ObjectFailure());
    }
  };

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const getOrderAction =
  (orderId: string, authToken?: string) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);

    dispatch({ type: GET_ORDER_REQUEST });

    try {
      const res = await getOrder({ orderId, idToken, authToken });

      await dispatch(setOrderGA4Object(res?.data?.order?.auctionId, authToken));

      dispatch({
        type: GET_ORDER_SUCCESS,
        payload: res?.data?.order
      });
    } catch (err: CheckoutError) {
      dispatch({
        type: GET_ORDER_FAILURE,
        payload: err
      });
    }
  };

export const GET_AVAILABLE_PICKUP_TIMESLOTS_REQUEST =
  'GET_AVAILABLE_PICKUP_TIMESLOTS_REQUEST';
export const GET_AVAILABLE_PICKUP_TIMESLOTS_SUCCESS =
  'GET_AVAILABLE_PICKUP_TIMESLOTS_SUCCESS';
export const GET_AVAILABLE_PICKUP_TIMESLOTS_FAILURE =
  'GET_AVAILABLE_PICKUP_TIMESLOTS_FAILURE';

export type GetAvailablePickupTimeslotsRequest = {
  type: 'GET_AVAILABLE_PICKUP_TIMESLOTS_REQUEST';
};
export type GetAvailablePickupTimeslotsSuccess = {
  type: 'GET_AVAILABLE_PICKUP_TIMESLOTS_SUCCESS';
  payload: GetAvailablePickupTimeslotsResponse['availableTimeslots'];
};

export const getAvailablePickupTimeslotsAction =
  (orderId: number) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const authToken = checkoutOrderAuthToken(state);

    dispatch({ type: GET_AVAILABLE_PICKUP_TIMESLOTS_REQUEST });
    await getAvailablePickupTimeslots({ orderId, idToken, authToken })
      .then(res => {
        dispatch({
          type: GET_AVAILABLE_PICKUP_TIMESLOTS_SUCCESS,
          payload: res.data.availableTimeslots
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: GET_AVAILABLE_PICKUP_TIMESLOTS_FAILURE,
          payload: err
        });
      });
  };

export const START_ORDER_REQUEST = 'START_ORDER_REQUEST';
export const START_ORDER_SUCCESS = 'START_ORDER_SUCCESS';
export const START_ORDER_FAILURE = 'START_ORDER_FAILURE';
export const startOrder =
  (auctionId: string, checkoutType: CheckoutType) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);

    dispatch({ type: START_ORDER_REQUEST });

    if (checkoutType === 'AUCTION') {
      return await getCheckoutOrderByAuctionId({
        idToken,
        auctionId,
        checkoutType
      })
        .then(({ data }) => {
          dispatch({
            type: START_ORDER_SUCCESS,
            payload: data.order
          });
        })
        .catch((err: CheckoutError) => {
          dispatch({ type: START_ORDER_FAILURE, payload: err });
        });
    }

    await startCheckoutOrder({ idToken, auctionId, checkoutType })
      .then(({ data }) => {
        dispatch({
          type: START_ORDER_SUCCESS,
          payload: data.order
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({ type: START_ORDER_FAILURE, payload: err });
      });
  };

export interface FinancingPostData {
  paymentMethod: PaymentMethod;
  insurance: boolean;
  downPayment?: number;
  loanPeriod?: number;
  warrantyPeriod: WarrantyMonths;
  serviceAgreement: boolean;
  includeBiddingFeeInMonthlyCost?: boolean;
  acceptsExtraTires?: boolean;
}

export const COMPLETE_CONFIGURE_STEP_REQUEST =
  'COMPLETE_CONFIGURE_STEP_REQUEST';
export const COMPLETE_CONFIGURE_STEP_SUCCESS =
  'COMPLETE_CONFIGURE_STEP_SUCCESS';
export const COMPLETE_CONFIGURE_STEP_FAILURE =
  'COMPLETE_CONFIGURE_STEP_FAILURE';
export const completeFinancingStep =
  () => async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const { paymentMethod, downPayment, paymentPeriodMonths } =
      financingRoot(state);
    const choices = warrantyChoicesSelector(state)?.choices || [];
    const orderId = checkoutOrderId(state);
    const services = checkoutSelectedServices(state);
    const warrantyPeriod = checkoutWarrantyMonths(state);
    const isBuyNow = checkoutType(state) === AuctionType.BUY_NOW;
    const isInterestedInExtraTires = interestedInExtraTires(state);

    /* Analytics */
    const orderGA4Object = orderGA4ObjectSelector(state);
    const ga4User = getGAUserFromState(state);

    const warrantyChoiceIncluded = choices.find(
      c => c.period === 6 && c.price === 0 && isBuyNow
    );

    const isLoan = paymentMethod === 'LOAN';

    const selectedWarranty = () => {
      if (services.includes('WARRANTY') && warrantyPeriod !== null) {
        return warrantyPeriod;
      } else if (warrantyChoiceIncluded) {
        return 6;
      } else {
        return null;
      }
    };

    const configure: FinancingPostData = {
      insurance: services.includes('INSURANCE'),
      paymentMethod,
      downPayment: isLoan ? downPayment : undefined,
      loanPeriod: isLoan ? paymentPeriodMonths : undefined,
      serviceAgreement: services.includes('SERVICE_AGREEMENT'),
      warrantyPeriod: selectedWarranty(),
      includeBiddingFeeInMonthlyCost:
        paymentMethod === 'CASH' ? undefined : true,
      acceptsExtraTires: isInterestedInExtraTires
    };

    dispatch({ type: COMPLETE_CONFIGURE_STEP_REQUEST });

    try {
      const { data } = await updateCheckoutFinancing(
        orderId,
        idToken,
        configure
      );

      dispatch({
        type: COMPLETE_CONFIGURE_STEP_SUCCESS,
        payload: data.order
      });

      if (orderGA4Object) {
        ga4TrackCheckoutFlow(
          {
            step_completed: 1,
            object: orderGA4Object
          },
          ga4User
        );
      }
    } catch (err: CheckoutError) {
      dispatch({
        type: COMPLETE_CONFIGURE_STEP_FAILURE,
        payload: err
      });
    }
  };

export const completeHeavyEquipmentFinancingStep =
  (interestInFinancing: boolean) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);

    /* Analytics */
    const orderGA4Object = orderGA4ObjectSelector(state);

    const ga4User = getGAUserFromState(state);

    dispatch({ type: COMPLETE_CONFIGURE_STEP_REQUEST });

    try {
      const { data } = await updateCheckoutHeavyEquipmentFinancing(
        orderId,
        idToken,
        {
          interestInFinancing,
          paymentMethod: 'CASH'
        }
      );

      dispatch({
        type: COMPLETE_CONFIGURE_STEP_SUCCESS,
        payload: data.order
      });

      if (orderGA4Object) {
        ga4TrackCheckoutFlow(
          {
            step_completed: 1,
            object: orderGA4Object
          },
          ga4User
        );
      }
    } catch (err: CheckoutError) {
      dispatch({
        type: COMPLETE_CONFIGURE_STEP_FAILURE,
        payload: err
      });
    }
  };

export const SET_INTEREST_IN_FINANCING = 'SET_INTEREST_IN_FINANCING';
export type SetInterestInFinancingAction = {
  type: typeof SET_INTEREST_IN_FINANCING;
  payload: boolean;
};
export const setInterestInFinancingAction = (interestInFinancing: boolean) => ({
  type: SET_INTEREST_IN_FINANCING,
  payload: interestInFinancing
});

export const COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_REQUEST =
  'COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_REQUEST';
export const COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS =
  'COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS';
export const COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_FAILURE =
  'COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_FAILURE';
export const completeConfigureCarDealerCheckoutStep =
  () => async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);
    const acceptsExtraTires = interestedInExtraTires(state);

    const payload = {
      introduction: { shownToOrderingMember: true, acceptsExtraTires }
    };

    dispatch({ type: COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_REQUEST });
    await updateCheckoutConfigureCarDealer(orderId, idToken, payload)
      .then(({ data }) => {
        dispatch({
          type: COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_SUCCESS,
          payload: data.order
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: COMPLETE_CONFIGURE_CAR_DEALER_CHECKOUT_STEP_FAILURE,
          payload: err
        });
      });
  };

interface CompleteBuyerStepArgs {
  payload?: CheckoutBuyer;
  isOrganisation?: boolean;
  isOrderingMember?: boolean;
  t: TranslateFunction;
}

export const COMPLETE_BUYER_STEP_REQUEST = 'COMPLETE_BUYER_STEP_REQUEST';
export const COMPLETE_BUYER_STEP_SUCCESS = 'COMPLETE_BUYER_STEP_SUCCESS';
export const COMPLETE_BUYER_STEP_FAILURE = 'COMPLETE_BUYER_STEP_FAILURE';
export const completeBuyerStep =
  ({
    payload,
    isOrganisation = false,
    isOrderingMember = true,
    t
  }: CompleteBuyerStepArgs) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);

    const member = memberSelector(state);
    const [address] = member.addresses ?? [];

    const memberDetails = {
      firstName: member.person.firstName,
      lastName: member.person.lastName,
      email: member.person.email,
      phoneNumber: member.person.phoneNumber,
      nationalIdentificationNumber:
        address.country === 'se'
          ? member.person.nationalIdentificationNumber
          : undefined
    };

    const orderingMember = {
      ...address,
      ...memberDetails,
      isOrderingMember,
      organisationName: member.person?.organisation?.name ?? '',
      organisationNumber: member.person?.organisation?.organisationNumber ?? '',
      address: address?.address1 ?? '',
      zipCode: address?.zipCode ?? undefined,
      city: address?.city ?? undefined,
      country: address?.country ?? undefined,
      memberType: member.memberType
    };

    let formattedPayload = payload;

    if (isOrderingMember) {
      formattedPayload = orderingMember;
    } else {
      if (!isOrganisation) {
        formattedPayload = {
          ...payload,
          organisationName: undefined,
          organisationNumber: undefined,
          vatNumber: undefined
        };
      } else {
        formattedPayload = {
          ...payload,
          nationalIdentificationNumber: undefined
        };
      }
    }

    /* Analytics */
    const orderGA4Object = orderGA4ObjectSelector(state);
    const ga4User = getGAUserFromState(state);

    dispatch({ type: COMPLETE_BUYER_STEP_REQUEST });
    await updateCheckoutBuyer(orderId, idToken, formattedPayload)
      .then(({ data }) => {
        if (orderGA4Object) {
          ga4TrackCheckoutFlow(
            {
              step_completed: 2,
              object: orderGA4Object
            },
            ga4User
          );
        }

        dispatch({
          type: COMPLETE_BUYER_STEP_SUCCESS,
          payload: {
            ...data.order,
            buyer: { ...data.order.buyer }
          }
        });

        if (getCheckoutStepState('FINANCING', data.order) !== 'INVALID') {
          return;
        }

        const snack: Snack = {
          key: 0,
          message: t('Financing settings was changed ..'),
          type: 'info',
          color: 'none'
        };

        dispatch({
          type: ADD_SNACK,
          payload: snack
        });
      })
      .catch((err: CheckoutError) => {
        if (isAxiosError<{ message: string }>(err)) {
          const message = err?.response?.data?.message ?? {
            common: DEFAULT_ERROR_MESSAGE
          };

          dispatch({
            type: COMPLETE_BUYER_STEP_FAILURE,
            payload: message
          });
        }
      });
  };

export const COMPLETE_DELIVERY_STEP_REQUEST = 'COMPLETE_DELIVERY_STEP_REQUEST';
export const COMPLETE_DELIVERY_STEP_SUCCESS = 'COMPLETE_DELIVERY_STEP_SUCCESS';
export const COMPLETE_DELIVERY_STEP_FAILURE = 'COMPLETE_DELIVERY_STEP_FAILURE';
export const completeDeliveryStep =
  (selectedTimeslot?: TimePickerSlot) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);
    const facilityId = checkoutFacilityId(state);
    const toFacilityId = checkoutToFacilityId(state);
    const pickupLocation = pickupLocationSelector(state);
    const deliveryLocation = checkoutDeliveryLocation(state);
    const currentFacility = checkoutCurrentFacility(state);

    /* Analytics */
    const orderGA4Object = orderGA4ObjectSelector(state);
    const ga4User = getGAUserFromState(state);

    const facilityDestination = {
      destinationFacilityId: toFacilityId || facilityId
    };
    const delivery =
      pickupLocation === PICKUP_LOCATIONS.LOCATION
        ? { deliveryLocation }
        : facilityDestination;

    const currentFacilityTimeslot =
      currentFacility?.id === facilityDestination.destinationFacilityId &&
      selectedTimeslot &&
      [PICKUP_LOCATIONS.FACILITY, PICKUP_LOCATIONS.CURRENT_FACILITY].includes(
        pickupLocation
      )
        ? {
            startTime: dayjs(selectedTimeslot.date).format('YYYY-MM-DD HH:mm'),
            endTime: dayjs(selectedTimeslot?.end).format('YYYY-MM-DD HH:mm')
          }
        : undefined;

    const payload = {
      ...delivery,
      currentFacilityTimeslot,
      wantsDelayedBooking: pickupLocation === PICKUP_LOCATIONS.DELAYED_BOOKING
    };

    dispatch({ type: COMPLETE_DELIVERY_STEP_REQUEST });
    await updateCheckoutDelivery(orderId, idToken, payload)
      .then(({ data }) => {
        dispatch({
          type: COMPLETE_DELIVERY_STEP_SUCCESS,
          payload: data.order
        });

        if (orderGA4Object) {
          ga4TrackCheckoutFlow(
            {
              step_completed: 3,
              object: orderGA4Object
            },
            ga4User
          );
        }
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: COMPLETE_DELIVERY_STEP_FAILURE,
          payload: err
        });
      });
  };

export const completeDeliveryStepHeavyEquipment =
  () => (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = checkoutOrderId(state);
    const pickupLocation = pickupLocationSelector(state);

    const orderGA4Object = orderGA4ObjectSelector(state);

    const ga4User = getGAUserFromState(state);

    dispatch({ type: COMPLETE_DELIVERY_STEP_REQUEST });
    updateCheckoutDelivery(orderId, idToken, {
      pickupMethod: pickupLocation
    })
      .then(({ data }) => {
        dispatch({
          type: COMPLETE_DELIVERY_STEP_SUCCESS,
          payload: data.order
        });

        if (orderGA4Object) {
          ga4TrackCheckoutFlow(
            {
              step_completed: 3,
              object: orderGA4Object
            },
            ga4User
          );
        }
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: COMPLETE_DELIVERY_STEP_FAILURE,
          payload: err
        });
      });
  };

export const GET_CHECKOUT_PRICES_REQUEST = 'GET_CHECKOUT_PRICES_REQUEST';
export const GET_CHECKOUT_PRICES_SUCCESS = 'GET_CHECKOUT_PRICES_SUCCESS';
export const GET_CHECKOUT_PRICES_FAILURE = 'GET_CHECKOUT_PRICES_FAILURE';
export const getCheckoutPrices =
  (input: FetchCheckoutPricesInput) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);

    dispatch({ type: GET_CHECKOUT_PRICES_REQUEST });
    fetchCheckoutPrices({
      ...input,
      idToken
    })
      .then(({ data }) => {
        dispatch({
          type: GET_CHECKOUT_PRICES_SUCCESS,
          payload: data
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: GET_CHECKOUT_PRICES_FAILURE,
          payload: err
        });
      });
  };

export const GET_TRANSPORT_PRICES_REQUEST = 'GET_TRANSPORT_PRICES_REQUEST';
export const GET_TRANSPORT_PRICES_SUCCESS = 'GET_TRANSPORT_PRICES_SUCCESS';
export const GET_TRANSPORT_PRICES_FAILURE = 'GET_TRANSPORT_PRICES_FAILURE';
export const getCheckoutTransportCosts =
  (_orderId?: string) =>
  (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const idToken = idTokenSelector(state);
    const orderId = _orderId || checkoutOrderId(state);

    dispatch({ type: GET_TRANSPORT_PRICES_REQUEST });
    getTransportCosts(orderId, idToken)
      .then(res => {
        dispatch({
          type: GET_TRANSPORT_PRICES_SUCCESS,
          payload: res
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: GET_TRANSPORT_PRICES_FAILURE,
          payload: err
        });
      });
  };

export const GET_HOME_DELIVERY_COST_REQUEST = 'GET_HOME_DELIVERY_COST_REQUEST';
export const GET_HOME_DELIVERY_COST_SUCCESS = 'GET_HOME_DELIVERY_COST_SUCCESS';
export const GET_HOME_DELIVERY_COST_FAILURE = 'GET_HOME_DELIVERY_COST_FAILURE';
export const getCheckoutHomeDeliveryCost =
  (address: DeliveryLocation) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const orderId = checkoutOrderId(state);
    const idToken = idTokenSelector(state);

    dispatch({ type: GET_HOME_DELIVERY_COST_REQUEST });
    await getHomeDeliveryCost(orderId, address, idToken)
      .then(res => {
        dispatch({
          type: GET_HOME_DELIVERY_COST_SUCCESS,
          payload: res.data,
          meta: address
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({ type: GET_HOME_DELIVERY_COST_FAILURE, payload: err });
      });
  };

export const CLEAR_HOME_DELIVERY_COST = 'CLEAR_HOME_DELIVERY_COST';
export const clearHomeDeliveryCost = () => ({
  type: CLEAR_HOME_DELIVERY_COST
});

export const CLEAR_ERRORS = 'checkout/CLEAR_ERRORS';
export const clearCheckoutErrors = () => ({
  type: CLEAR_ERRORS
});

export const CLEAR_BUYER_ERRORS = 'checkout/CLEAR_BUYER_ERRORS';
export const clearBuyerErrors = () => ({
  type: CLEAR_BUYER_ERRORS
});

export const SUBMIT_ORDER_REQUEST = 'checkout/SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'checkout/SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'checkout/SUBMIT_ORDER_FAILURE';
export const submitCheckout =
  (submit: Record<string, unknown>, t: TranslateFunction) =>
  async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const orderId = checkoutOrderId(state);
    const idToken = idTokenSelector(state);

    /* Analytics */
    const orderGA4Object = orderGA4ObjectSelector(state);
    const ga4User = getGAUserFromState(state);

    dispatch({ type: SUBMIT_ORDER_REQUEST });

    if (orderGA4Object) {
      ga4TrackCheckoutFlow(
        {
          step_completed: 4,
          object: orderGA4Object
        },
        ga4User
      );
    }

    await checkoutSubmit(orderId, idToken, submit)
      .then(({ data }) => {
        dispatch({
          type: SUBMIT_ORDER_SUCCESS,
          payload: data.order
        });

        if (orderGA4Object) {
          ga4TrackCheckoutFlow(
            {
              step_completed: 5,
              object: orderGA4Object
            },
            ga4User
          );
        }
      })
      .catch((err: CheckoutError) => {
        const snack = {
          key: 0,
          message: t(
            'Something went wrong, please check all steps and try again'
          ),
          type: 'error',
          color: 'error'
        };

        dispatch({
          type: ADD_SNACK,
          payload: snack
        });

        dispatch({
          type: SUBMIT_ORDER_FAILURE,
          payload: err
        });
      });
  };

export const GET_LOAN_COST_REQUEST = 'checkout/GET_LOAN_COST_REQUEST';
export const GET_LOAN_COST_SUCCESS = 'checkout/GET_LOAN_COST_SUCCESS';
export const GET_LOAN_COST_FAILURE = 'checkout/GET_LOAN_COST_FAILURE';
export const checkoutGetLoanCost =
  () => async (dispatch: ReduxDispatch, getState: () => ReduxStore) => {
    const state = getState();
    const orderId = checkoutOrderId(state);
    const idToken = idTokenSelector(state);
    const prices = checkoutPricesSelector(state);
    const paymentPeriod = checkoutPaymentPeriod(state);
    const processObject = checkoutProcessObject(state);
    const shouldUseResidualValue =
      processObject && getAllowedResidualLoan(processObject);

    dispatch({ type: GET_LOAN_COST_REQUEST });
    await getLoanCost({
      orderId,
      idToken,
      price: prices.loan.total.price ?? 0,
      downPayment: prices.downPayment,
      loanPeriod: paymentPeriod,
      residualValuePercentage: shouldUseResidualValue ? 0.5 : 0
    })
      .then(res => {
        dispatch({
          type: GET_LOAN_COST_SUCCESS,
          payload: res.data
        });
      })
      .catch((err: CheckoutError) => {
        dispatch({
          type: GET_LOAN_COST_FAILURE,
          payload: err
        });
      });
  };
