import { Button, Stack } from '@kvdbil/components';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import EmailField from '~/App/shared/components/Fields/shared/components/EmailField';
import { regexEmail } from '~/helpers/regex';
import { useTranslation } from '~/Locale';
import BankIdAuthButton from '../../components/BankIdAuthButton';
import { TermsCheckbox } from './TermsCheckbox';
import { RegistrationView } from '../types';
import { StyledCheckboxContainer } from './StyledCheckboxContainer';
import { useParamFromQuery } from '~/App/shared/hooks/useQueryParams';
import { NewsletterCheckboxes } from './NewsletterCheckboxes';
import { createEmailSubscriptionNames } from '~/App/shared/helpers/general';
import { SubscriptionType } from '~/App/shared/interfaces/EmailSubscription';

type Props = {
  onBankIdAuthStart(
    email: string,
    emailSubscriptionNames: SubscriptionType[]
  ): void;
  isLoading: boolean;
  email: string | null;
  changeView: (view: RegistrationView) => void;
};

const CreatePrivateAccountForm = ({
  changeView,
  onBankIdAuthStart,
  isLoading,
  email
}: Props) => {
  const { t } = useTranslation();

  const emailFromParams = useParamFromQuery('email');
  const hasEmailRef = useRef(
    Boolean(emailFromParams && regexEmail.test(emailFromParams))
  );

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email,
      acceptsMinimumAgeRequirement: false,
      personalCarSubscription: true,
      heavyVehiclesSubscription: true
    }
  });

  return (
    <form
      data-testid="create-private-account-form"
      onSubmit={handleSubmit(
        ({ email, personalCarSubscription, heavyVehiclesSubscription }) => {
          const emailSubscriptionNames = createEmailSubscriptionNames({
            personalCarSubscription,
            heavyVehiclesSubscription
          });
          email && onBankIdAuthStart(email, emailSubscriptionNames);
        }
      )}
    >
      <Stack spacing={2} style={{ textAlign: 'left' }}>
        <EmailField
          isDisabled={hasEmailRef.current}
          control={control}
          t={t}
          data-testid="input-email"
        />
        <StyledCheckboxContainer direction="column" gap={1}>
          <TermsCheckbox control={control} />

          <NewsletterCheckboxes control={control} />

          <BankIdAuthButton
            isDisabled={!formState.isValid}
            isLoading={isLoading}
          >
            {t('Create account with BankID')}
          </BankIdAuthButton>
        </StyledCheckboxContainer>

        <Button
          onClick={() => changeView('NO_BANKID')}
          variant="flat"
          color="neutral"
          type="button"
          size="regular"
        >
          {t(`Don't have BankID`)}
        </Button>
      </Stack>
    </form>
  );
};

export default CreatePrivateAccountForm;
