import {
  TransferInfoPageQuery,
  PurchaseAndPickUpTransferQuery
} from '~/config/generated/graphql';

export type TransferInfoObject = ReturnType<typeof handleTransferInfo>;

export const handleTransferInfo = ({
  purchaseOwnershipTransfer
}: TransferInfoPageQuery) => ({
  ...purchaseOwnershipTransfer
});

export const handlePurchaseAndPickUpTransfer = ({
  purchaseAndPickUpTransfer
}: PurchaseAndPickUpTransferQuery) => ({
  purchase: {
    title: purchaseAndPickUpTransfer?.purchaseTitle,
    description: purchaseAndPickUpTransfer?.purchaseDescription,
    vehicleCardTitle: purchaseAndPickUpTransfer?.purchaseVehicleCardTitle,
    formTitle: purchaseAndPickUpTransfer?.purchaseFormTitle,
    formDescription: purchaseAndPickUpTransfer?.purchaseFormDescription,
    explanation: purchaseAndPickUpTransfer?.purchaseExplanation,
    initializedStatusDescription:
      purchaseAndPickUpTransfer?.purchaseInitializedStatusDescription,
    completeStatusDescription:
      purchaseAndPickUpTransfer?.purchaseCompleteStatusDescription,
    status: {
      initialized: {
        title: purchaseAndPickUpTransfer?.commonSigningInitializedTitle,
        description:
          purchaseAndPickUpTransfer?.purchaseInitializedStatusDescription
      },
      complete: {
        title: purchaseAndPickUpTransfer?.purchaseCompleteStatusTitle,
        description:
          purchaseAndPickUpTransfer?.purchaseCompleteStatusDescription
      },
      failed: {
        title: purchaseAndPickUpTransfer?.commonSigningFailedTitle,
        description: purchaseAndPickUpTransfer?.commonSigningFailedDescription
      },
      aborted: {
        title: purchaseAndPickUpTransfer?.commonSigningAbortedTitle,
        description: purchaseAndPickUpTransfer?.commonSigningAbortedDescription
      },
      notFound: {
        title: purchaseAndPickUpTransfer?.commonSigningNotFoundTitle,
        description: purchaseAndPickUpTransfer?.commonSigningNotFoundDescription
      }
    }
  },
  pickUp: {
    title: purchaseAndPickUpTransfer?.pickUpTitle,
    description: purchaseAndPickUpTransfer?.pickUpDescription,
    vehicleCardTitle: purchaseAndPickUpTransfer?.pickUpVehicleCardTitle,
    formTitle: purchaseAndPickUpTransfer?.pickUpFormTitle,
    formIdentificationQuestion:
      purchaseAndPickUpTransfer?.pickUpFormIdentificationQuestion,
    status: {
      initialized: {
        title: purchaseAndPickUpTransfer?.commonSigningInitializedTitle,
        description:
          purchaseAndPickUpTransfer?.pickUpInitializedStatusDescription
      },
      complete: {
        title: purchaseAndPickUpTransfer?.pickUpCompleteStatusTitle,
        description: purchaseAndPickUpTransfer?.pickUpCompleteStatusDescription
      },
      failed: {
        title: purchaseAndPickUpTransfer?.commonSigningFailedTitle,
        description: purchaseAndPickUpTransfer?.commonSigningFailedDescription
      },
      aborted: {
        title: purchaseAndPickUpTransfer?.commonSigningAbortedTitle,
        description: purchaseAndPickUpTransfer?.commonSigningAbortedDescription
      },
      notFound: {
        title: purchaseAndPickUpTransfer?.commonSigningNotFoundTitle,
        description: purchaseAndPickUpTransfer?.commonSigningNotFoundDescription
      }
    }
  }
});
