import { AxiosError } from 'axios';

export type ErrorResponse<K extends number | string | symbol> = {
  message: Record<K, string[] | Record<K, string[]>> | string;
};

export const isAxiosError = <T extends ErrorResponse<string>>(
  error: unknown
): error is AxiosError<T> => {
  return Boolean(
    error &&
      typeof error === 'object' &&
      'isAxiosError' in error &&
      (error as AxiosError)['isAxiosError']
  );
};

export const isNetworkError = (response: AxiosError): boolean => {
  if (response.message === 'Network Error') {
    return true;
  }

  if (response.code === 'ECONNABORTED') {
    return true;
  }

  return false;
};
