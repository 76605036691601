export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

export const isKeyOfObject = <T>(
  key: string | number | symbol,
  obj: T
): key is keyof T => {
  return key in obj;
};
