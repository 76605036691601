import Bugsnag from '@bugsnag/js';
import { AxiosError, isAxiosError } from 'axios';

type ErrorSeverity = 'error' | 'warning' | 'info';

interface Options {
  severity?: ErrorSeverity;
  unhandled?: boolean;
  request?: {
    clientIp?: string;
    headers?: { [key: string]: string };
    httpMethod?: string;
    referer?: string;
    url?: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

export const hideSensitiveData = (
  obj: Record<string, unknown> | null,
  keys: string[]
) => {
  if (typeof obj !== 'object' || obj === null) return;

  for (const prop in obj) {
    if (obj[prop] === Object(obj[prop]))
      hideSensitiveData(obj[prop] as Record<string, unknown>, keys);
    else if (keys.includes(prop)) obj[prop] = 'HIDDEN';
  }
};

export const isValidError = (error: unknown): error is Error | AxiosError => {
  return error instanceof Error;
};

export const log = (
  error?: Error | AxiosError | unknown,
  options: Options = {}
) => {
  if (!error || !isValidError(error)) {
    return;
  }

  /* If this is not a Error or AxiosError */
  if (!(error instanceof Error) && !('response' in error)) {
    return;
  }

  const { severity = 'error', ...extraData } = options;
  Bugsnag.notify(error, event => {
    if (Object.keys(extraData).length > 0) {
      hideSensitiveData(extraData, ['Authorization']);
      event.addMetadata('extraData', extraData);
    }

    event.unhandled = Boolean(extraData.unhandled);
    event.severity = severity;

    if (isAxiosError(error)) {
      event.request = {
        ...event.request,
        ...error.request
      };
    } else if (options.request) {
      event.request = {
        ...event.request,
        ...options.request
      };
    }
  });
};
