import { getUserId } from '~/App/shared/hooks/useUserId';
import Auction from '~/App/shared/interfaces/Auction';
import { ReduxStore } from '~/App/shared/interfaces/store';
import {
  isPrivateCustomer,
  isMemberOrganisationPersonSelector
} from '~/App/shared/selectors/sessionSelector';
import { isHeavyEquipmentObject } from '~/App/views/ObjectPage/helpers';
import { auctionTypeIsBidding } from '../buyCar';
import { isCookieCategoryConsent } from '../cookieConsent';

export type GA4ObjectType = 'auction' | 'fixed_price';
export type SellType = GA4ObjectType;

export interface GA4ObjectProperties {
  id: string;
  name: string;
  brand: string;
  type: GA4ObjectType;
  price?: number;
  speculators?: number;
  reserver_price_reached?: boolean;
  bids?: number;
  is_heavy_equipment: boolean;
}

export interface GA4AuctionObjectProperties extends GA4ObjectProperties {
  id: string;
  name: string;
  brand: string;
  type: 'auction';
  price: undefined;
  speculators: undefined;
  reserver_price_reached: boolean;
  bids: number;
}

export interface GA4FixedPriceObjectProperties extends GA4ObjectProperties {
  id: string;
  name: string;
  brand: string;
  type: 'fixed_price';
  price: number;
  speculators: number;
  reserver_price_reached: undefined;
  bids: undefined;
}

export type GA4SiteSearchObjectProperties = {
  searchTerms: string;
  url: string;
};

// we cant know what T is but eventParams needs to be extended by T
export type GA4EventParams<T> = {
  object?:
    | GA4ObjectProperties
    | GA4AuctionObjectProperties
    | GA4FixedPriceObjectProperties
    | GA4SiteSearchObjectProperties;
} & T;

export type GA4UserType = 'private' | 'company' | undefined;

export interface GA4UserProps {
  user_id?: string;
  user_type?: GA4UserType;
  is_car_dealer?: boolean;
}

export interface GA4Event<T = GA4ObjectProperties> {
  event: string;
  event_params?: GA4EventParams<T>;
  user_props?: GA4UserProps;
}

// creates a GA4 event with the correct structure
export const createGA4Event = <T>(
  event: string,
  eventParems: GA4EventParams<T>,
  userProps: GA4UserProps = createGA4User()
): GA4Event<T> => ({
  event,
  event_params: eventParems,
  user_props: userProps
});

// pushes a GA4 event to the dataLayer
export const pushGA4Event = <T>(event: GA4Event<T>) => {
  if (!isCookieCategoryConsent('marketing')) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

// helper function that creates and pushes GA4 event in one.
export const trackGA4Event = <T>(
  event: string,
  eventParams: GA4EventParams<T>,
  userProps: GA4UserProps
) => pushGA4Event<T>(createGA4Event<T>(event, eventParams, userProps));

// helper function that creates and pushes GA4 event with no parameters.
export const trackGA4EventWithNoParams = (event: string) =>
  pushGA4Event({ event });

// makes sure that the user object is correct
export const createGA4User = (
  userId?: string,
  userType?: GA4UserType,
  isCarDealer?: boolean
): GA4UserProps => ({
  user_id: userId,
  user_type: userType,
  is_car_dealer: isCarDealer
});

export const getGAUserFromState = (state: ReduxStore) => {
  const userId = getUserId(state);

  const isPrivateUser = isPrivateCustomer(state);
  const isOrganisationUser = isMemberOrganisationPersonSelector(state);

  const isCarDealer = state?.session?.member?.person?.isCarDealer;

  if (isOrganisationUser) {
    return createGA4User(userId, 'company', isCarDealer);
  }

  if (isPrivateUser) {
    return createGA4User(userId, 'private', isCarDealer);
  }

  return createGA4User(userId);
};

// makes sure that the auction object is correct
export const createGA4AuctionObject = (
  id: string,
  name: string,
  brand: string,
  isHeavyEquipment: boolean,
  reserverPriceReached: boolean,
  bids: number
): GA4AuctionObjectProperties => ({
  id,
  name,
  brand,
  type: 'auction',
  price: undefined,
  speculators: undefined,
  reserver_price_reached: reserverPriceReached,
  bids,
  is_heavy_equipment: isHeavyEquipment
});

// makes sure that the fixed price object is correct
export const createGA4FixedPriceObject = (
  id: string,
  name: string,
  brand: string,
  isHeavyEquipment: boolean,
  price: number,
  speculators: number
): GA4FixedPriceObjectProperties => ({
  id,
  name,
  brand,
  type: 'fixed_price',
  price: parseFloat(price?.toFixed(2)),
  speculators,
  reserver_price_reached: undefined,
  bids: undefined,
  is_heavy_equipment: isHeavyEquipment
});

// handels ether auction or fixed price just incase
export const createGA4Object = (
  id: string,
  name: string,
  brand: string,
  type: 'auction' | 'fixed_price',
  isHeavyEquipment: boolean,
  price?: number,
  speculators?: number,
  reserverPriceReached?: boolean,
  bids?: number
): GA4ObjectProperties => {
  if (type === 'auction') {
    return createGA4AuctionObject(
      id,
      name,
      brand,
      isHeavyEquipment,
      reserverPriceReached as boolean,
      bids as number
    );
  }

  return createGA4FixedPriceObject(
    id,
    name,
    brand,
    isHeavyEquipment,
    price as number,
    speculators as number
  );
};

export const objectPropertiesFromAuction = (
  auction: Auction
): GA4ObjectProperties => {
  return createGA4Object(
    auction.id,
    auction.slug,
    auction.processObject?.brand,
    auctionTypeIsBidding(auction.auctionType) ? 'auction' : 'fixed_price',
    isHeavyEquipmentObject(auction?.processObject),
    auction.buyNowAmount ?? undefined,
    auction.auctionViews,
    auction.activeAuction?.reservationPriceReached,
    auction.activeAuction?.bids?.length
  );
};

type GA4PageViewType = 'dynamic' | 'hard';
export interface GA4PageViewEventParams {
  document_title: string;
  document_href: string;
  pageview_type: GA4PageViewType;
  previous_url?: string;
}

export const trackGA4PageView = (
  pageViewEventParams: GA4PageViewEventParams,
  userProps: GA4UserProps
) =>
  trackGA4Event<GA4PageViewEventParams>(
    'page_view',
    pageViewEventParams,
    userProps
  );

export interface ObjectEventParams {
  object: GA4ObjectProperties;
}

export const trackGA4ObjectEvent = (
  event: string,
  objectViewEventParams: ObjectEventParams,
  userProps: GA4UserProps
) => trackGA4Event<ObjectEventParams>(event, objectViewEventParams, userProps);

export interface GA4CategoryViewEventParams {
  category_type: GA4ObjectType;
}

export const trackGA4CategoryView = (
  categoryVeiwEventParams: GA4CategoryViewEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event<GA4CategoryViewEventParams>(
    'view_category',
    categoryVeiwEventParams,
    userProps
  );
};

export const trackGA4AccountCreated = (userProps: GA4UserProps) =>
  trackGA4Event('account_created', {}, userProps);

export interface GA4CheckoutFlowEventParams {
  step_completed: number;
  object: GA4ObjectProperties;
}

export const ga4TrackCheckoutFlow = (
  checkoutFlowEventParams: GA4CheckoutFlowEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event<GA4CheckoutFlowEventParams>(
    'checkout_flow',
    checkoutFlowEventParams,
    userProps
  );
};

export interface GA4ValuationFlowEventParams {
  step_completed: 1 | 2 | 3 | 4 | 5;
  sell_type?: SellType;
}

export const trackGA4ValuationFlow = (
  valuationFlowEventParams: GA4ValuationFlowEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event<GA4ValuationFlowEventParams>(
    'valuation_flow',
    valuationFlowEventParams,
    userProps
  );
};

export type GA4ABTestExtraParams = {
  member_id?: string;
  ab_test?: string;
  ab_test_version?: string;
};
export const trackGA4ValuationFlowForABTest = (
  valuationFlowEventParams: GA4ValuationFlowEventParams & GA4ABTestExtraParams,
  userProps: GA4UserProps
) => {
  trackGA4Event<GA4ValuationFlowEventParams & GA4ABTestExtraParams>(
    'valuation_flow',
    valuationFlowEventParams,
    userProps
  );
};

type LoginType = 'kvd_login' | 'facebook_login' | 'bankid_login';
export interface GA4LoginEventParams {
  login_type: LoginType;
}

export const trackGA4Login = (
  loginEventParams: GA4LoginEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event<GA4LoginEventParams>('login', loginEventParams, userProps);
};

export const trackGA4NewsletterSignup = (userProps: GA4UserProps) => {
  trackGA4Event('newsletter_signup', {}, userProps);
};

export interface GA4SellCarViewEventParams {
  page_type: 'sell_car_page' | 'value_car_page';
}

export const trackGA4SellCarView = (
  sellCarViewEventParams: GA4SellCarViewEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event('view_sell_car_page', sellCarViewEventParams, userProps);
};

export interface ProductFilterEventParams {
  filter_group: string;
  filter_value: string;
  filter_page: string;
}
export const trackGA4ProductFilter = (
  productFilterEventParams: ProductFilterEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event('product_filter', productFilterEventParams, userProps);
};

export interface GA4TrackBidPlacedEventParams extends ObjectEventParams {
  nth_bid: number;
}

export const trackGA4BidPlaced = (
  bidPlacedEventParams: GA4TrackBidPlacedEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event<GA4TrackBidPlacedEventParams>(
    'bid_placed',
    {
      ...bidPlacedEventParams,
      nth_bid: bidPlacedEventParams.nth_bid + 1
    },
    userProps
  );
};

export interface SiteSearchEventParams {
  search_parameter: string;
  search_landing_page: string;
}

export const trackGA4SiteSearch = (
  siteSearchEventParams: SiteSearchEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event('site_search', siteSearchEventParams, userProps);
};

export const trackGA4SaveSearch = (userProps: GA4UserProps) => {
  trackGA4Event('save_search', {}, userProps);
};

export interface FilterContentCardEventParams {
  card_type: string;
}

export const trackGA4FilterContentCard = (
  filterContentCardEventParams: FilterContentCardEventParams,
  userProps: GA4UserProps
) => {
  trackGA4Event('filter_content_card', filterContentCardEventParams, userProps);
};

export const trackGA4PageNotFound = () => {
  trackGA4EventWithNoParams('page_not_found');
};
