import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import qs from 'qs';
import { orchestration } from '~/config/public';
import {
  FacilityWithAvailabilities,
  Facility,
  FacilityFilters
} from '~/App/shared/interfaces/Facility';

export type GetFacilitiesParams = {
  params?: {
    orderByPostCode?: string;
    includeSubmissionCost?: boolean;
    salesFlow?: 'CONSUMER_CARS' | 'CORPORATE_CARS_SMALL';
    facilityService?: 'RECEPTION' | 'DELIVERY';
    objectType?: FacilityFilters[];
  };
};

export const getFacilities = (
  req: GetFacilitiesParams = {},
  signal?: AbortSignal
) =>
  axios
    .get<{ facilities: Facility[] }>(`${orchestration.url}/common/facilities`, {
      params: req.params || {},
      paramsSerializer: (params: Record<string, string>) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
      responseType: 'json',
      signal
    })
    .then(({ data }) => {
      return data.facilities;
    });

export const getFacilitiesWithAvailabilities = (
  req: GetFacilitiesParams = {},
  signal?: AbortSignal
) =>
  axios
    .get<{ facilities: FacilityWithAvailabilities[] }>(
      `${orchestration.url}/common/facility-availabilities`,
      {
        params: req.params || {},
        responseType: 'json',
        signal
      }
    )
    .then(({ data }) => {
      const { facilities } = data;

      return facilities;
    });

export const getFacility = ({ slug }: { slug: string }, signal?: AbortSignal) =>
  axios
    .get<{ facility: Facility }>(
      `${orchestration.url}/common/facilities/${slug}`,
      {
        responseType: 'json',
        signal
      }
    )
    .then(({ data }) => data.facility);
